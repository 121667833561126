import { IdentityProvider, IdentityState, LogoutTrigger, useIdentity } from '@contember/interface'
import { CircleAlert } from 'lucide-react'
import { useEffect } from 'react'
import { dict } from '~/lib/dict'
import { Button } from '~/lib/ui/button'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '~/lib/ui/card'
import { Loader } from '~/lib/ui/loader'
import { Overlay } from '~/lib/ui/overlay'
import { Login } from '../components/login'
import { entrypointConfig } from '../config'

export default () => {
	return (
		<IdentityProvider>
			<IdentityState state={['none', 'cleared']}>
				<Login
					appUrl={entrypointConfig.appUrl}
					hasTokenFromEnv={entrypointConfig.hasTokenFromEnv}
					idps={entrypointConfig.idps}
					magicLink={entrypointConfig.magicLink}
				/>
			</IdentityState>
			<IdentityState state="success">
				<LoggedIn />
			</IdentityState>
			<IdentityState state="loading">
				<Loader />
			</IdentityState>
			<IdentityState state="failed">
				<Overlay>
					<Card className="w-72">
						<CardContent className="flex flex-col items-center gap-2">
							<CircleAlert className="h-12 w-12 text-destructive" />
							<p className="text-center text-lg text-gray-600">{dict.identityLoader.fail}</p>
							<LogoutTrigger>
								<Button>Login again</Button>
							</LogoutTrigger>
						</CardContent>
					</Card>
				</Overlay>
			</IdentityState>
		</IdentityProvider>
	)
}

const LoggedIn = () => {
	const identity = useIdentity()
	useEffect(() => {
		if (identity?.person?.email) {
			setTimeout(() => {
				window.location.href = entrypointConfig.appUrl
			}, 500)
		}
	}, [identity?.person?.email])

	if (!identity?.person?.email) {
		return (
			<Login
				appUrl={entrypointConfig.appUrl}
				hasTokenFromEnv={entrypointConfig.hasTokenFromEnv}
				idps={entrypointConfig.idps}
				magicLink={entrypointConfig.magicLink}
			/>
		)
	}

	return (
		<Card className="w-96 relative">
			<CardHeader>
				<CardTitle className="text-2xl">Logged in </CardTitle>
				<CardDescription>as {identity?.person?.email ?? 'unknown'}</CardDescription>
			</CardHeader>
			<Loader position="static" />
		</Card>
	)
}
